<template>
  <div class="user_profile__card">
    <b-row align-h="center">
      <b-col cols="12" md="8">
        <h1 class="user_profile__title">Подписки</h1>
        <p class="block_subtitle"></p>
      </b-col>
    </b-row>

    <b-row v-if="subscription && subscription.expiration_date != null">
      <b-col cols="12">
        <SubCard v-if="subscription.type" :item="subscription.type" />
      </b-col>
    </b-row>

    <div
      v-if="
        subscription &&
        subscription.expiration_date &&
        subscription.expiration_date != null
      "
    >
      <!-- Active -->
      <b-row align-h="start" class="user__subscribe_button_block">
        <b-col cols="12">
          <p class="user_profile__subtitle">Статус:</p>

          <div>
            <p class="user_profile__data">
              {{
                subscriptionEnd()
                  ? "Закончилась: " + formatDate(subscription.expiration_date)
                  : "Активна до: " + formatDate(subscription.expiration_date)
              }}
            </p>
          </div>

          <!-- <div v-else>
            <b-row>
              <b-col>
                <p class="user_text">Не активна</p>
              </b-col>
            </b-row>
          </div> -->

          <b-row align-h="start" align-v="end">
            <b-col cols="auto">
              <p class="user_profile__subtitle">Тип:</p>

              <p class="user_profile__data">{{ subscription.type.title }}</p>
            </b-col>

            <b-col cols="auto">
              <button
                type="button"
                @click="$router.push({ name: 'Subscribe' })"
                :disabled="loading"
              >
                <p class="user_text button_text">Изменить</p>
              </button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- Exp date today/yesterday || null -->
      <div
        v-if="
          (subscription.saved_payment_method == null && subscriptionEnd()) ||
          subscription.expiration_date == null
        "
      >
        <b-row>
          <b-col>
            <button
              @click="subscribe()"
              :disabled="loading"
              class="main__button main__button subs_button"
            >
              Перейти к оплате
            </button>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- No subscription - get FREE -->
    <div
      v-else-if="
        (subscription && subscription.expiration_date == null) ||
        subscription == null
      "
    >
      <b-row>
        <b-col>
          <button
            @click="$router.push({ name: 'FreeWeek' })"
            class="main__button subs_button"
          >
            Начать изменения
          </button>
        </b-col>
      </b-row>
    </div>

    <!-- Autosubscription -->
    <b-row v-if="subscription && subscription.saved_payment_method">
      <b-col>
        <button
          type="button"
          :disabled="loading"
          @click="turnOffAutoSubscribe()"
          class="user_text button_text"
        >
          Отключить автопродление
        </button>
      </b-col>
    </b-row>

    <b-row v-else-if="subscription && subscription.expiration_date != null">
      <b-col>
        <button
          type="button"
          :disabled="loading"
          @click="turnOnAutoSubscribe()"
          class="user_text button_text"
        >
          Включить автопродление
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import SubCard from "./SubCard.vue";
import { mapActions } from "vuex";

export default {
  name: "Subscription",
  components: {
    SubCard,
  },
  computed: {
    subscription() {
      return this.$store.state.user.subscription;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    ...mapActions(["getUser"]),
    async subscribe() {
      try {
        this.$store.commit("SET_LOADING", true);

        let confirmationUrl = await axios
          .post("subscription", {
            subscription_type_id: this.subscription.type.id,
          })
          .then((res) => {
            return res.data.confirmation_url;
          });

        location.replace(confirmationUrl);

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
    formatDate(date) {
      return moment(date).format("D MMMM YYYY");
    },
    subscriptionEnd() {
      return (
        moment(this.subscription.expiration_date).format("YYYY-MM-DD") <
        moment(new Date()).format("YYYY-MM-DD")
      );
    },
    async turnOnAutoSubscribe() {
      try {
        this.$store.commit("SET_LOADING", true);

        const payURL = await axios
          .post("turn_on_auto_subscriptions", { typePay: "bank_card" })
          .then((res) => {
            return res.data.confirmation_url;
          });
        document.location = payURL;

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);

        console.error(e);
      }
    },
    async turnOffAutoSubscribe() {
      try {
        this.$store.commit("SET_LOADING", true);
        await axios.delete("disable_autosubscription");

        await this.getUser();

        this.$bvToast.toast("Автопродление отключено");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);

        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
.user__subscribe_button_block {
  padding-bottom: 15px;
}
.main__button.subs_button {
  margin-bottom: 15px;
}
.user_text.button_text {
  font-size: 16px;
  padding: 2px 15px;
  border: 1px solid $accent;
  border-radius: 10px;
}
</style>
